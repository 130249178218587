const PropertySubType = Object.freeze({
  APARTMENT_NORMAL: 'APARTMENT_NORMAL',
  APARTMENT_ATTIC: 'APARTMENT_ATTIC', // Dachgeschoss
  APARTMENT_MAISONETTE: 'APARTMENT_MAISONETTE',
  APARTMENT_PENTHOUSE: 'APARTMENT_PENTHOUSE',
  APARTMENT_STUDIO: 'APARTMENT_STUDIO',
  APARTMENT_TERRACED: 'APARTMENT_TERRACED', // Erdgeschoss
  HOUSE_DETACHED: 'HOUSE_DETACHED',
  HOUSE_SEMI_DETACHED: 'HOUSE_SEMI_DETACHED',
  HOUSE_ROW_CORNER: 'HOUSE_ROW_CORNER',
  HOUSE_ROW_MIDDLE: 'HOUSE_ROW_MIDDLE',
  HOUSE_FARM: 'HOUSE_FARM'
})

export default PropertySubType
