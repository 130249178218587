import PropertyType from '@/lib/enums/valuation/PropertyType'
import PropertySubType from '@/lib/enums/valuation/PropertySubType'

export const propertyTypes = {
  [PropertyType.HOUSE]: 'Haus',
  [PropertyType.APARTMENT]: 'Wohnung / Apartment',
  [PropertyType.MULTI_FAMILY_HOUSE]: 'Mehrfamilienhaus'
}

export const propertySubTypes = {
  [PropertySubType.APARTMENT_NORMAL]: 'Etagenwohnung',
  [PropertySubType.APARTMENT_ATTIC]: 'Dachgeschoss',
  [PropertySubType.APARTMENT_MAISONETTE]: 'Maisonette',
  [PropertySubType.APARTMENT_PENTHOUSE]: 'Penthouse',
  [PropertySubType.APARTMENT_STUDIO]: 'Studio',
  [PropertySubType.APARTMENT_TERRACED]: 'Erdgeschoss',
  [PropertySubType.HOUSE_DETACHED]: 'Freistehend',
  [PropertySubType.HOUSE_SEMI_DETACHED]: 'Doppelhaushälfte',
  [PropertySubType.HOUSE_ROW_CORNER]: 'Reihenendhaus',
  [PropertySubType.HOUSE_ROW_MIDDLE]: 'Reihenhaus',
  [PropertySubType.HOUSE_FARM]: 'Landhaus'
}
